.ant-input-lg {
  height: 40px;
}
.ant-message {
  font-size: 12px;
  font-weight: 500;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 30px;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

// Popup Styles
.success__popup {
  width: 661px !important;
}

.success__popup .ant-modal-content {
  width: 661px;
  height: 301px;
}

.success__popup--client {
  .ant-modal-content {
    height: 341px !important;
  }
}

.success__popup img {
  margin-bottom: 24px;
}

.success__popup button {
  height: 40px;
  padding: 0 40px;
  font-weight: 600;
  font-size: @text-md;
}

.success__popup__title {
  font-weight: 700;
  font-size: @text-mlg;
  margin-bottom: 8px;
}

.success__popup__desc {
  margin-bottom: 12px;
  color: #435465;
}
.success__popup__btn {
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
}

// Select styles
.ant-select,
input {
  width: 100%;
  font-size: @text-sm !important;
  font-weight: 500;
}
.ant-select-item-option {
  font-weight: 500;
}

.ant-picker {
  height: 40px !important;
}
