.per_table {
  width: 100%;
  border-collapse: collapse;
}

.per_table_td {
  padding: 32px;
  border: 1px solid #d1d1d1;
  text-align: center;
  font-weight: 600;
}

.per_table_td:nth-child(2) {
  border-left: none;
}

.per_table_td:first-child {
  text-align: left;
  border-right: none;
}

.per_table_th {
  padding: 22px 32px;
  background-color: #f6f6f6;
  border-left: 1px solid #d1d1d1;
  text-align: center;
}

.per_table_th:first-child {
  text-align: left;
  border-left: none;
}

.per_table_th:nth-child(2) {
  border-left: none;
}

.helping_text {
  font-size: 12px;
  color: #000000;
  opacity: 0.7;
  font-weight: 400;
}

@hack: true; @import "./src/styles/theme.less";