.offers {
  padding-bottom: 65px;
  .ant-collapse-content-box {
    padding: 0 !important;
  }
  .ant-collapse-header {
    background-color: #e7e7e7;
    color: #000;
    font-weight: 600;

    .expand-icon {
      display: flex;
      align-items: center;
      span {
        font-size: 12px;
        margin-right: 8px;
        position: relative;
        top: 1px;
      }
    }
  }
  &__steps {
    display: flex;
    justify-content: center;
    &__step {
      display: flex;
      align-items: center;
      &__circle {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        background-color: #e4e4e4;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: @text-ml;
        font-weight: 600;
        margin-right: 12px;
        &__num {
          color: #000;
          opacity: 0.2;
        }
      }
      &__title {
        color: #000;
        opacity: 0.2;
        font-weight: 600;
        line-height: 1.3;
        font-size: @text-sm;
        white-space: pre-line;
      }
      &__border {
        margin: 0 30px;
        background-color: transparent;
        width: 250px;
        opacity: 0.2;
        border-width: 2px;
        height: 1px;
        background-image: linear-gradient(to right, black 60%, transparent 40%);
        background-position: top;
        background-size: 12px 1px;
        &--active {
          opacity: 1;
          background-image: linear-gradient(
            to right,
            #4faa89 100%,
            transparent 100%
          );
        }
      }
      &--active {
        .offers__steps__step__circle {
          background-color: #4faa89 !important;
          &__num {
            opacity: 1;
            color: @white !important;
          }
        }
        .offers__steps__step__title {
          opacity: 1 !important;
        }
      }
    }
  }
  &__form {
    background-color: white;
    border-radius: 2px;
    &__offerModeller {
      &__headerBtns {
        padding: 16px;
      }
      &__btns-col {
        display: flex;
        align-items: center;
      }
    }
    &__gap {
      height: 24px;
      background-color: #f0f2f5 !important;
    }
    &__tabs {
      background-color: #f0f2f5;
    }
    &__header {
      padding: 24px;
      border-bottom: @border;
    }
    &__fields {
      padding: 32px 24px;
      border-bottom: @border;
      &__checkbox {
        font-size: 12px;
        font-weight: 600;
      }
      &__picker {
        width: 100%;
      }
      &__btns {
        margin-top: 56px;
        button {
          &:first-of-type {
            padding: 0 87px;
          }
          &:not(:first-of-type) {
            padding: 0 40px;
          }
        }
      }
    }

    &__job-info {
      label {
        margin: 0 !important;
      }
      &__headerCol {
        background-color: #fafafa;
        padding: 15px 25px;
        border-bottom: 1px solid #66666631;
        &:last-of-type {
          border-left: 1px solid #66666631;
        }
        .sub-heading {
          color: #000 !important;
        }
      }
      &__col {
        &:last-of-type {
          border-left: 1px solid #66666631;
        }
        padding: 16px 24px;
        &--nested {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
        }
        &--addBtn {
          margin-top: 10px;
          cursor: pointer;
          span {
            color: #4faa89;
            font-weight: 600;
            padding-left: 12px;
            font-size: @text-md;
          }
        }
      }
    }
    &__addBtn {
      margin-top: 10px;
      cursor: pointer;
      span {
        color: #4faa89;
        font-weight: 600;
        padding-left: 12px;
        font-size: @text-md;
      }
      &--sm {
        span {
          font-size: @text-sm;
        }
      }
    }
    &__salary-offer {
      .right-border {
        border-right: 1px solid #66666631;
      }
      &__nested {
        .offers__form__salary-offer__row {
          label {
            padding-left: 28px;
          }
        }
      }
      &__row {
        height: 49px;
        display: flex;
        align-items: center;

        &--header {
          border-bottom: 1px solid #66666631;
        }

        .sub-heading {
          color: #000 !important;
        }
      }
      &__fields {
        .offers__form__salary-offer__row {
          height: 60px;
        }
      }

      &__col {
        display: flex;
        align-items: center;
        height: 100%;
        .ant-checkbox {
          margin-right: 12px;
        }
        &:not(:nth-of-type(3)) {
          border-right: 1px solid #66666631;
        }
        &:not(:first-of-type) {
          justify-content: center;
          display: flex;
        }
      }
    }
  }
  &__modal {
    &__row {
      padding: 24px 32px 40px 32px;
      &:not(:last-of-type) {
        border-bottom: @border-light-gray;
      }
    }
    &__title {
      color: #4faa89;
      font-weight: 600;
      font-size: @text-md;
      margin-bottom: 24px;
    }
  }
}

@hack: true; @import "./src/styles/theme.less";