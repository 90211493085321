@font-face {
  font-family: "HK Grotesk";
  src: url(../assets/fonts/HKGrotesk-Regular.otf) format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "HK Grotesk";
  src: url(../assets/fonts/HKGrotesk-Light.otf) format("opentype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "HK Grotesk";
  src: url(../assets/fonts/HKGrotesk-SemiBold.otf) format("opentype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "HK Grotesk";
  src: url(../assets/fonts/HKGrotesk-Bold.otf) format("opentype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "Hando";
  src: url(../assets/fonts/HandoTrial-Regular.otf) format("opentype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Hando Light";
  src: url(../assets/fonts/HandoTrial-Light.otf) format("opentype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "Hando Bold";
  src: url(../assets/fonts/HandoTrial-Bold.otf) format("opentype");
  font-style: normal;
  font-weight: 600;
}
