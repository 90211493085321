.table {
  font-weight: 500 !important;
  &--client {
    .ant-table-thead {
      .ant-table-cell {
        color: #000 !important;
      }
    }
  }
  .ant-table-thead .ant-table-cell {
    padding-top: 13px !important;
    padding-bottom: 13px !important;
  }

  .ant-table-tbody .ant-table-cell {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 49px !important;
  }
  .table__action__menu {
    display: flex;
    justify-content: center;
  }

  .table__filter__icon {
    fill: @secondary-text;
  }
  &__input {
    width: 50%;
  }
  .ant-table-thead {
    .ant-table-cell {
      color: @secondary-text;
      font-size: @text-sm;
      font-weight: 600;
      text-transform: uppercase;

      &::before {
        display: none;
      }

      .ant-table-filter-column {
        width: min-content;
        white-space: nowrap;

        .ant-table-column-title {
          margin-right: 2.2rem;
        }
      }
    }
  }
  .table__action__btn {
    color: #566eeb;
    text-align: center;
    display: inline-block;
    font-size: @text-mds;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    &:nth-of-type(2) {
      margin-left: 24px;
    }
    &--delete {
      color: @error-color-light !important;
    }
  }
  .table__action__btn--client {
    color: #4faa89;
  }
  .ant-skeleton,
  .ant-skeleton-input {
    width: 100% !important;
  }
  .ant-skeleton {
    margin-top: 16px;
    &:last-of-type {
      margin-bottom: 16px;
    }
  }
  &__pagination {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 16px 0;
  }
}

.onRowHover tr.ant-table-row.ant-table-row-level-0:hover {
  cursor: pointer !important;
}

@hack: true; @import "./src/styles/theme.less";