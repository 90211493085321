.transparent {
  background-color: #f0f2f5 !important;
}
.height-40 {
  height: 40px;
}
.salary-offer {
  .visible {
    opacity: 1 !important;
  }

  &__btns {
    margin: 0;
  }

  &__parent-col {
    &:last-of-type {
      border-left: 1px solid #66666631;
    }
  }
  &__header-row {
    height: 49px;
    background-color: #fafafa;
    display: flex;
    align-items: center;
    padding-left: 24px;
  }
  &__content-row {
    height: 100%;
    &--last {
      height: calc(100% - 48px);
    }
    label {
      margin: 0 !important;
    }

    &__col {
      &:first-of-type {
        .salary-offer__content-row__field {
          padding: 0 24px;
          label {
            color: #000 !important;
            font-weight: 500;
            font-size: @text-sm;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      &:not(:first-of-type) {
        border-left: 1px solid #66666631;
        .salary-offer__content-row__field {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 24px;
        }
      }
    }
    &__field-upper {
      height: 49px;
      font-weight: 600;
      color: @primary-black !important;
      font-size: @text-md;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #66666631;
      border-bottom: 1px solid #66666631;
      background-color: #fafafa;
    }
    &__field {
      height: 65px;
      width: 100%;
      align-items: center;
      display: flex;

      &__remove {
        width: 20%;
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        svg {
          cursor: pointer;
          transform: rotate(45deg);
        }
      }
      &__no-unit {
        width: 100%;
      }
      &__inner {
        display: flex;
        align-items: center;
        width: 100%;
        input {
          width: 78%;
        }
      }
      &__unit {
        font-weight: 600;
        width: 22%;

        display: flex;
        justify-content: flex-end;
      }
      &--nested {
        opacity: 0.3;

        label {
          padding-left: 24px;
        }
      }
      &--add-more {
        margin: 25px 0;
        height: 60px;
        cursor: pointer;
        padding-left: 24px;
        display: flex;
        align-items: center;

        span {
          color: #4faa89;
          font-weight: 600;
          padding-left: 12px;
          font-size: @text-md;
        }
      }
      &--totalBB {
        height: 72px;
        border-top: 1px solid #66666631;
        border-bottom: 1px solid #66666631;
      }
      &--total {
        height: 72px;
        border-top: 1px solid #66666631;
      }
    }
  }
}

@hack: true; @import "./src/styles/theme.less";