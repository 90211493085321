@form-padding: 20px;

.container {
  padding-bottom: 17px;
}
.addCompany {
  background-color: white;
  border-radius: 2px;
  &__content {
    padding: 32px 24px;
  }
  &__header {
    padding: 24px;
    border-bottom: @border;
  }
  &__col2 {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    padding-right: 32px;
    justify-content: center;
    &__image {
      width: 160px;
      height: 160px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &--hidden {
        visibility: hidden !important;
      }
      .spinner {
        font-size: 40px !important;
        position: absolute;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &__row {
    border-bottom: @border;
    margin: 0 -24px;
    padding: 0 24px;
  }
  &__stockIds {
    width: 100%;
    display: flex;
    justify-content: space-between;
    input {
      width: calc(95% - 42px);
    }
    &__list {
      padding: 10px 0 0 0;
      &__id {
        background-color: @primary-white;
        padding: 5px 10px;
        border-radius: 2px;
        font-weight: bold;
        color: @secondary-text;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__txt {
          padding-right: 10px;
        }

        svg {
          cursor: pointer;
          font-size: 16px;
        }
      }
    }
  }
  &__number {
    .ant-input-affix-wrapper {
      width: 100% !important;
    }
  }
  &__btns {
    padding: 32px 0 0 0;
    button {
      &:first-of-type {
        margin-right: 24px;
        padding-left: 40px;
        padding-right: 40px;
      }
      &:last-of-type {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
}

.container {
  padding-bottom: @form-padding;
}

.form_heading {
  font-size: @text-lg;
  font-weight: 600;
}

.create__company__container {
  background-color: @white;
}

.section__heading {
  padding: @form-padding;
  font-size: @text-md;
  color: @primary-black;
  border-top: @border-light-gray;
}

.basic_information_section_heading {
  border-bottom: @border-light-gray;
  border-top: none;
}

.form__section {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.form__section_container {
  flex: 1;
  padding: @form-padding;
}

.form__section_upload {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: @form-padding;
}

.financial_year_container,
.contract__details_year_container {
  width: 120%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.contract__details_year_container {
  width: 59%;
}

.datepicker__container {
  width: 47%;
}

.img_description_text {
  font-size: @text-xs;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 10px;
}
.img_description_size {
  font-size: @text-sm;
  text-align: center;
  margin: 0px;
}

.contact__person__sub_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.contact__person_item {
  flex: 1;
  margin-right: 50px;
}

.form__submit__section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 320px;
  padding: @form-padding;
}

.img_description_text {
  color: @primary-color;
  font-size: @text-xs;
}

.img_description_size {
  color: @secondary-text;
  font-size: @text-xs;
}

.stock_tracking_id__container {
  display: flex;
}

.stock_tracking_id__container > input {
  margin-right: 25px;
}

.form__section__container__row {
  display: flex;
  align-items: center;
  width: max-content;
  .ant-row {
    &:last-of-type {
      margin-left: 64px;
    }
  }
  & div {
    width: 264px;
  }
}

@hack: true; @import "./src/styles/theme.less";