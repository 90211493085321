.companies {
  &__upper {
    display: flex;
    justify-content: space-between;
    &__searchbar {
      width: 25vw;
      border: none;
    }
  }
}

.tom-toggle {
  margin-left: 103px;
}

@hack: true; @import "./src/styles/theme.less";