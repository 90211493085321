@primary-color: #2f49d1;
@primary-color-light: #8fa6e3;
@primary-white: #f0f0f0;
@primary-black: #0b2540;
@primary-black-light: #212b26;
@secondary-text: #435465;
@white: #ffffff;
@light-gray: #d9d9d9;
@green-light: #23dbaf;
@blue-light: #23a7d0;
@error-color: #fa1818;
@error-color-light: #d83939;
@border: 1px solid @primary-white;
@border-primary: 1px solid @primary-color;
@border-light-gray: 0.75px solid @light-gray;
@link: #2f49d1;
@sider-link: #fff;

body,
.ant-table-tbody .ant-table-cell {
  color: @secondary-text !important;
}

.primary-color {
  color: @primary-color !important;
}

.secondary-color {
  color: @secondary-text !important;
}

.link {
  color: @link !important;
}

.sider {
  background-color: @link !important;
  a {
    color: @primary-color-light;
    &:hover {
      color: @sider-link !important;
    }
  }
  &__active {
    color: @sider-link !important;
    .sider__icon__container--client {
      svg,
      g,
      path {
        opacity: 1 !important;
        fill: @primary-color !important;
      }
    }
  }
}

.ant-switch-checked {
  background-color: @primary-color !important;
}

.ant-modal-footer button,
.secondary-btn {
  &:not(:first-of-type) {
    border-color: @link !important;
    color: @link !important;
    margin-left: 16px !important;
  }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active,
.secondary-btn[disabled],
.secondary-btn[disabled]:hover,
.secondary-btn[disabled]:focus,
.secondary-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.ant-input:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-picker:hover,
.ant-picker-focused,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked::after,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: @primary-color !important;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-btn-primary {
  background-color: @primary-color !important;
  border-color: @primary-color !important;
  color: white !important;
}

.tab--active,
.tab:hover {
  background-color: @primary-color !important;
}

@hack: true; @import "./src/styles/theme.less";