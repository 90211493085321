.btn {
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  &:first-of-type {
    margin-left: 0 !important;
  }
  &__icon {
    width: 15px;
    max-width: 15px;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__children {
    font-size: @text-sm;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-left: 16px;
  }
  &--download_client {
    background-color: @primary-black-light !important;
    color: white !important;
    border: 1px solid @primary-black-light !important;
    margin-left: 16px;
  }
  &--upload {
    background-color: @green-light !important;
  }
  &--versions {
    background-color: @whiskey !important;
    color: white;
    margin-left: 16px;
  }
  &--download {
    background-color: transparent !important;
    color: @blue-light !important;
    border: 1px solid @blue-light !important;
    margin-left: 16px;
  }
  &--add {
    width: 209px;
    display: flex;
    justify-content: center;
  }
  &--addIndustry {
    width: 184px;
    display: flex;
    justify-content: center;
  }
}

.no-margin {
  margin: 0;
}

@hack: true; @import "./src/styles/theme.less";