.container {
  position: relative;
}

.photo {
  width: 55vw;
}

.logo__container {
  position: absolute;
  top: 40px;
  left: 10%;
  height: auto;
  width: 9.5rem;
  z-index: 1;

  svg {
    width: 100%;
    height: auto;
  }
}

.heading {
  color: white !important;
  font-family: @secondary-font;
  position: absolute;
  bottom: 30%;
  left: 17%;
  width: 54%;
  font-size: @text-xxl !important;
}

@hack: true; @import "./src/styles/theme.less";