.auth__container {
  height: 100vh;
  overflow: hidden;
  .form__input {
    width: 100% !important;
  }
}

.auth__right {
  display: flex;
  align-items: center;
  height: 100vh;
}

.auth__form__container {
  width: 70%;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
}

.auth__form_title {
  font-family: @secondary-font;
  font-weight: 700;
  font-size: @text-xl;
  margin-bottom: 0 !important;
}

.auth__form__prompt {
  font-family: @secondary-font;
  font-weight: 300;
  font-size: @text-md;
  margin-bottom: 0 !important;
}

.auth__form {
  margin-top: 3rem;
}

.form__item {
  margin-bottom: 33px;
}

.form__item .ant-form-item-label {
  padding-bottom: 6px;

  .ant-form-item-required {
    width: 100%;
  }
  .ant-form-item-required::before {
    display: none !important;
  }
}

.input__label {
  font-size: @text-md;
  font-family: @secondary-font;
  font-weight: 600;
}

.error__label {
  font-size: @text-md;
  font-family: @secondary-font;
  font-weight: 600;
  color: @error-color;
}

.auth__password__label {
  font-size: @text-md;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  a {
    font-size: @text-sm;
  }
}

.form__input {
  border: 1px solid #435465;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.auth__error__msg {
  font-family: @secondary-font;
  font-size: @text-xs;
  color: @error-color;
}

.auth__checkbox__item {
  margin-bottom: 3rem;
  font-family: @secondary-font;
  font-size: @text-sm;
}

.login__btn {
  width: 100%;
  font-size: @text-md;
  font-family: @secondary-font;
}

.backToLogin {
  color: @primary-color;
  font-weight: bold;
}
.auth__container {
  .ant-form-item-explain.ant-form-item-explain-error {
    font-weight: 500;
    font-size: @text-sm;
    margin-top: 6px;
  }
}

@hack: true; @import "./src/styles/theme.less";