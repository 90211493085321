.regions {
  position: relative;

  &__addBtn {
    position: absolute;
    right: 0;
    z-index: 9;
    top: -88px;
    right: 0;

    &--hidden {
      display: none;
    }
  }
}

@hack: true; @import "./src/styles/theme.less";