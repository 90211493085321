.uploadSalaryRange {
  &__uploadField {
    position: relative;
    &__btn {
      position: absolute;
      right: 4px;
      bottom: 4px;
    }
  }
}

@hack: true; @import "./src/styles/theme.less";