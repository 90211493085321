@form-padding: 20px;

.container {
  padding-bottom: @form-padding;
}

.form_heading {
  font-size: 24px;
  font-weight: 600;
}

.create__company__container {
  background-color: #fff;
}

.section__heading {
  padding: @form-padding;
  font-size: 16px;
  color: #0b2540;
  border-bottom: 0.75px solid #d9d9d9;
}

.form__item {
  margin-bottom: 33px;
}

.form__section {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.form__section_container {
  flex: 1;
  padding: @form-padding;
}

.contact__person__sub_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.contact__person_item {
  flex: 1;
  margin-right: 50px;
}

.input__label {
  font-size: @text-md;
  font-family: @secondary-font;
  font-weight: 600;
}

.form__submit__section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 320px;
  padding: @form-padding;
}

.form__input {
  border: 0.75px solid #d9d9d9;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ant-input-affix-wrapper {
  height: 40px;
}

.form__select {
  width: 25vw !important;

  .ant-select-selection-overflow-item {
    width: auto !important;
  }
}

.select__option_item {
  width: 43ch;
}

.ant-select-selector {
  // height: 39.7px !important;
  // padding-top: 4px !important;
}

@hack: true; @import "./src/styles/theme.less";