.addGradeCompany {
  background-color: white;
  border-radius: 2px;

  &__header {
    padding: 24px;
    border-bottom: @border;
  }

  &__fields {
    padding: 32px 24px;
    border-bottom: @border;
    &--optional {
      color: @error-color-light;
      font-weight: 400;
    }
  }

  &__title {
    padding: 24px;
    width: 100%;
  }
  &__lastRow {
    display: flex;
    width: 100%;
    height: 49px;
    align-items: center;
    justify-content: flex-end;
    padding: 0 32px;
    &__button {
      color: @primary-color;
      font-weight: 600;
      font-size: @text-md;
      cursor: pointer;
      svg {
        margin-right: 16px;
      }
    }
  }
  &__buttons {
    margin-top: 40px;
    padding-bottom: 16px;
    button {
      height: 40px;
      font-weight: 600;
      font-size: 15px;
      &:last-of-type {
        margin-left: 24px;
        color: @primary-color;
        background-color: transparent;
        border-color: @primary-color;
      }
    }
  }
}

@hack: true; @import "./src/styles/theme.less";