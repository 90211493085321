.empty {
  padding: 111px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    font-size: @text-ml;
    color: @secondary-text;
    font-weight: 600;
    margin: 22px 0 12px 0;
  }
  &__message {
    font-size: @text-md;
    font-weight: 500;
    margin-bottom: 56px;
    text-align: center;
  }
}

@hack: true; @import "./src/styles/theme.less";